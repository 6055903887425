import React, { useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
// import { DropDown, Menu } from '../components/common/sidebarMenu';
import { UserContext } from "../context/theme";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import common from "../services/common";

function Header() {
    const { auth, setAuth, setUserInfo } = useContext(UserContext);
    const Logout = () => {
        localStorage.removeItem("token");
        setAuth(false);
    };

    const getProfile = async () => {
        await authAxios({
            method: "GET",
            url: `/user/profile`,
        })
            .then((res) => {
                setUserInfo(res?.data?.data || {});
            })
            .catch((error) => {
                common.error(error);
            });
    };

    function refreshtoken() {
        authAxios({
            method: "GET",
            url: `/refreshToken`,
        })
            .then((res) => {
                reactLocalStorage.set("token", res?.data?.data?.token);
            })
            .catch((error) => {
                // reactLocalStorage.remove("token");
            });
    }
    useEffect(() => {
        let intrVal;
        if (auth) {
            intrVal = setInterval(() => {
                refreshtoken();
            }, 10000);
        }
        if (auth) {
            // getStoreCounts();
            getProfile();
        }
        return () => clearInterval(intrVal);
    }, [auth]);

    return (
        <>
            <div className="left-sidebar-wrapper">
                <nav className="sidebar">
                    <div className="sidebar-header text-center">
                        <NavLink activeclassname="isActive" to="/dashboard">
                            <img src={`/assets/images/logo.png`} className="img-fluid" alt="logo" />
                        </NavLink>
                        {/* <NavLink activeclassname="isActive" to="/dashboard">NYC Admin</NavLink> */}
                        <strong><NavLink activeclassname="isActive" to='/dashboard'><img src={`/assets/images/logo.png`} className="img-fluid" alt="logo" /></NavLink></strong>
                    </div>
                    {/* <NavLink activeclassname="isActive" to="/dashboard">
                        <img src={`/assets/images/dummy-logo.png`} className="img-fluid" alt="logo" />
                    </NavLink>

                    <strong>
                        <NavLink activeclassname="isActive" to="/dashboard">
                            <img src={`/assets/images/logo-small.png`} alt="logo" />
                        </NavLink>
                    </strong> */}

                    <div className="left-nav-menu">
                        <DropDownMenu>
                            <Menu path="/dashboard" title="Dashboard" icon="icon-wrap icon-home" />
                            <Menu path="/admins" title="Admins" icon="fa fa-user" />
                            <Menu path="/products" title="Buildings" icon="fa fa-table" />
                            <Menu path="/change-password" title="Change Password" icon="fa fa-key" />
                        </DropDownMenu>
                    </div>
                    <div className="version text-center">
                        <span className="vs-mobile">{common.version()}</span>
                    </div>
                </nav>
            </div>
        </>
    );
}
export default Header;
