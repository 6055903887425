import React from "react";
import { useContext } from "react";
import {
  Route,
  Navigate,
  RouteProps,
} from "react-router-dom";
import { UserContext } from "../context/theme";


const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const {auth} = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate
              to={{
                pathname: "/login",
                // state: { from: props["location"] },
              }}
            />
          );
        }

        // const loggedInUser = api.getLoggedInUser();

        // // check if route is restricted by role
        // if (roles && roles.indexOf(loggedInUser.role) === -1) {
        //   // role not authorised so redirect to home page
        //   return <Navigate to={{ pathname: "/" }} />;
        // }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
