import React, { Suspense, useContext, useEffect } from "react";

import Header from "./header";
import Footer from "./footer";

import { Routes, Route } from "react-router-dom";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../components/loader/loader";
const loading = () => <div className=""></div>;


function DashLayout({children}) {
    const context = useContext(UserContext);
    

    return (
        
            <div
                className={`page-wrapper 
                    ${context.Theme} 
                    ${context.ThemeColor} 
                    ${context.MiniSidebar ? "mini-sidebar" : ""}      
                    `}
                >
                
                <Suspense fallback={loading()}><Header /></Suspense>
                <div className="all-content-wrapper">
                    <Suspense fallback={loading()}><TopNavbar /></Suspense>
                    <Loader />
                    <Suspense fallback={loading()}>{children}</Suspense>
                </div>
                <Footer />
            </div>
        
    );
}

export default DashLayout;
